import React from 'react';
import Carousel from 'nuka-carousel';
import { LeftControl } from './LeftControl';
import { RightControl } from './RightControl';

interface Props {
  children: React.ReactNode;
}

export const CarouselPhotos = ({ children }: Props) => {
  return (
    <Carousel
      wrapAround
      renderCenterLeftControls={LeftControl}
      renderCenterRightControls={RightControl}
    >
      {children}
    </Carousel>
  );
};
