import React from 'react';
import slice from 'lodash/slice';
import { Grid } from '../../../../../components/Grid';
import { GridCell } from '../../../../../components/GridCell';
import styles from '../../Gallery.module.css';
import { HorizontalPhotoGallery } from './HorizontalPhotoGallery';
import { MainPhotoInGallery } from './MainPhotoGallery';
import { RegularPhotoGallery } from './RegularPhotoGallery';

interface OptionsGallery {
  id: string;
  photo: string;
}

interface Props {
  photos: OptionsGallery[];
  onClick: () => void;
  currentPhotoGallery: any;
  onSharedClick: any;
  model?: string;
  color?: string;
  type: 'vehicle' | 'product';
  dataValue?: string;
  withoutMainPhoto?: boolean;
}

export const ListPhotosDesktop = ({
  photos,
  onClick,
  currentPhotoGallery,
  onSharedClick,
  model,
  color,
  dataValue,
  type,
  withoutMainPhoto,
}: Props) => {
  if (!photos || photos.length === 0) {
    return null;
  }
  const [mainPhoto] = photos;
  const partsRegularPhotos = slice(photos, 1, photos.length);

  const fetchRegularPhotos = (gallery: OptionsGallery[]) => {
    const galleryLength = gallery.length > 7 ? 7 : gallery.length;
    const regularPhotosGallery = slice(gallery, 1, galleryLength);
    return regularPhotosGallery;
  };

  const mainPhotoGallery = (
    <MainPhotoInGallery
      setCurrentPhoto={currentPhotoGallery}
      mainPhoto={mainPhoto}
      onClick={onClick}
      onlyMainPhoto={photos.length > 1}
      onSharedClick={onSharedClick}
      model={model}
      color={color}
      dataValue={dataValue}
      photos={type === 'product' ? partsRegularPhotos : null}
      withoutMainPhoto={withoutMainPhoto}
    />
  );

  switch (type) {
    case 'product':
      return (
        <section>
          <div className={styles.listPhotoDesktop}>{mainPhotoGallery}</div>
          <HorizontalPhotoGallery
            setCurrentPhoto={currentPhotoGallery}
            regularPhotosGallery={partsRegularPhotos}
            onClick={onClick}
            model={model}
            color={color}
          />
        </section>
      );
    default: {
      return (
        <Grid className={styles.listPhotoDesktop}>
          <GridCell width={photos.length > 1 ? [1 / 2] : 1} className="h-100">
            {mainPhotoGallery}
          </GridCell>
          <GridCell width={[1 / 2]} className="h-100">
            <RegularPhotoGallery
              setCurrentPhoto={currentPhotoGallery}
              regularPhotosGallery={fetchRegularPhotos(photos)}
              onClick={onClick}
              model={model}
              color={color}
            />
          </GridCell>
        </Grid>
      );
    }
  }
};
