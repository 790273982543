import React, { FunctionComponent } from 'react';
import MediaQuery from 'react-responsive';

export const MediaMobile: FunctionComponent = ({ children }) => (
  <MediaQuery maxWidth={767}>{children}</MediaQuery>
);

export const MediaTablet: FunctionComponent = ({ children }) => (
  <MediaQuery minWidth={768} maxWidth={1023}>
    {children}
  </MediaQuery>
);

export const MediaDesktop: FunctionComponent = ({ children }) => (
  <MediaQuery minWidth={1024}>{children}</MediaQuery>
);

export const MediaMedium: FunctionComponent = ({ children }) => (
  <MediaQuery minWidth={768}>{children}</MediaQuery>
);

export const MediaLarge: FunctionComponent = ({ children }) => (
  <MediaQuery minWidth={1024} maxWidth={1365}>
    {children}
  </MediaQuery>
);

export const MediaAboveLarge: FunctionComponent = ({ children }) => (
  <MediaQuery minWidth={1366}>{children}</MediaQuery>
);
