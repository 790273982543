import React from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { MediaDesktop } from '../../../../../components/MediaWrapper';
import { callAll } from '../../../../../utils/callAll';
import styles from '../../Gallery.module.css';

interface Props {
  regularPhotosGallery: { id: string; photo: string }[];
  onClick: () => void;
  setCurrentPhoto: any;
  model?: string;
  color?: string;
}

interface PhotoBoxProps {
  src: string;
  alt: string;
  title: string;
  onClick: () => void;
  numberRemaining?: number;
}

const PhotoBox = ({ src, alt, title, onClick, numberRemaining }: PhotoBoxProps) => {
  const photoBoxClass = cx(
    'flex justify-center items-center white f36 absolute bg-black-40',
    styles.photoBox,
  );
  const componenteToRender = numberRemaining ? (
    <div className={photoBoxClass}>
      <span>+{numberRemaining + 1}</span>
    </div>
  ) : null;
  return (
    <div className="flex items-center overflow-hidden h-100 mr8" onClick={onClick}>
      <img src={src} alt={alt} title={title} className={styles.photoBox} />
      {componenteToRender}
    </div>
  );
};

export const HorizontalPhotoGallery = ({
  regularPhotosGallery,
  onClick,
  setCurrentPhoto,
  model,
  color,
}: Props) => {
  const handleCurrentPhoto = (e: number) => setCurrentPhoto(e + 1);

  const numberOfPhotos = regularPhotosGallery.length;
  const photos = numberOfPhotos > 5 ? regularPhotosGallery.slice(0, 5) : regularPhotosGallery;
  const numberRemaining = numberOfPhotos - 5;

  const listPhotos = map(photos, (item, idx) => {
    return (
      <PhotoBox
        key={item.id + item.photo}
        src={item.photo}
        alt={`Foto da galeria ${idx + 1}: ${model} ${color}`}
        onClick={callAll(onClick, handleCurrentPhoto(idx))}
        title={model || ''}
        numberRemaining={idx + 1 === 5 ? numberRemaining : 0}
      />
    );
  });

  return (
    <MediaDesktop>
      <div className="flex overflow-hidden h-100 mt12 mb8">{listPhotos}</div>
    </MediaDesktop>
  );
};
