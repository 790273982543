import React, { useState } from 'react';
import { ReactBnbGallery } from 'react-bnb-gallery';
import MediaQuery from 'react-responsive';
import { ListPhotosDesktop } from './ListPhotosDesktop';
import { ListPhotosMobile } from './ListPhotosMobile';

interface OptionsGallery {
  id: string;
  photo: string;
}

interface Props {
  gallery: OptionsGallery[];
  onSharedClick: any;
  model?: string;
  color?: string;
  type: 'vehicle' | 'product';
  dataValue?: string;
  withoutMainPhoto?: boolean;
}

export const ContainerListPhotos = ({
  gallery,
  onSharedClick,
  model,
  color,
  type,
  dataValue,
  withoutMainPhoto,
}: Props) => {
  const [openGallery, setOpenGallery] = useState(false);
  const [currentPhotoClicked, setCurrentPhotoClicked] = useState(0);
  const toggleGallery = () => {
    setOpenGallery(!openGallery);
  };
  return (
    <div>
      <MediaQuery minWidth={768}>
        <ListPhotosDesktop
          photos={gallery}
          onClick={toggleGallery}
          currentPhotoGallery={setCurrentPhotoClicked}
          onSharedClick={onSharedClick}
          model={model}
          color={color}
          dataValue={dataValue}
          type={type}
          withoutMainPhoto={withoutMainPhoto}
        />
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <ListPhotosMobile photos={gallery} model={model} color={color} />
      </MediaQuery>
      <ReactBnbGallery
        show={openGallery}
        photos={gallery}
        onClose={toggleGallery}
        phrases={{ phrase: '' }}
        activePhotoIndex={currentPhotoClicked}
      />
    </div>
  );
};
