import React from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import styles from '../../Gallery.module.css';

interface Props {
  regularPhotosGallery: { id: string; photo: string }[];
  onClick: () => void;
  setCurrentPhoto: any;
  model?: string;
  color?: string;
}

const handleQtdPhotoStyleDiv = (arrayLength: number) => {
  switch (arrayLength) {
    case 1:
      return 'mb0';
    case 2:
      return styles.twoPhotosWrapper;
    case 3:
      return styles.threePhotosWrapper;
    case 4:
      return styles.fourPhotosWrapper;
    case 5:
      return styles.fivePhotosWrapper;
    case 6:
      return styles.sixFivePhotosWrapper;
    default:
      return styles.threePhotosWrapper;
  }
};

const handleQtdPhotoStyleImg = (arrayLength: number) => {
  if (arrayLength === 2) {
    return styles.regularTwoPhotos;
  } else {
    return styles.regularOnePhoto;
  }
};

export const RegularPhotoGallery = ({
  regularPhotosGallery,
  onClick,
  setCurrentPhoto,
  model,
  color,
}: Props) => {
  const handleOpenCurrentPhoto = (e: string) => {
    onClick();
    setCurrentPhoto(e);
  };
  const regularPhoto = cx(
    'pointer overflow-hidden relative transition-ease h-auto w-100',
    styles.overlayGallery,
    styles.photoGalleryImg,
    styles.objectFit,
    [handleQtdPhotoStyleImg(regularPhotosGallery.length)],
  );

  const photoWrapperClasses = cx(
    'flex items-center overflow-hidden h-100 w-100 flex-grow-0 mr8',
    { mb8: regularPhotosGallery.length > 1 },
    [handleQtdPhotoStyleDiv(regularPhotosGallery.length)],
  );

  const listPhotos = () => {
    return map(regularPhotosGallery, item => (
      <div
        className={photoWrapperClasses}
        key={item.id + item.photo}
        // tslint:disable-next-line: jsx-no-lambda
        onClick={() => handleOpenCurrentPhoto(item.id)}
      >
        <img
          src={item.photo}
          alt={`Foto da galeria ${item.id}: ${model} ${color}`}
          title={model}
          className={regularPhoto}
        />
      </div>
    ));
  };

  return <div className="flex flex-wrap content-center overflow-hidden h-100">{listPhotos()}</div>;
};
