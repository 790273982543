import React from 'react';
import cx from 'classnames';
import { CarouselSlideRenderControlProps } from 'nuka-carousel';
import { Icon } from '../Icons';
import style from './style.module.css';

export const LeftControl = ({ previousSlide }: CarouselSlideRenderControlProps) => {
  const styleControl = cx('bg-transparent b bn white h-100 pointer', style.carouselControls);

  const handlePreviousSlide = (event: {
    stopPropagation: () => void;
    preventDefault: () => void;
  }) => {
    event.stopPropagation();
    event.preventDefault();
    previousSlide();
  };

  return (
    <button
      className={styleControl}
      onClick={handlePreviousSlide}
      title="Anterior"
      aria-label="Anterior"
    >
      <Icon name="SvgIconArrowleft" />
    </button>
  );
};
