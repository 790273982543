import React from 'react';
import cx from 'classnames';
import { CarouselSlideRenderControlProps } from 'nuka-carousel';
import { Icon } from '../Icons';
import styles from './style.module.css';

export const LeftControl = ({ previousSlide }: CarouselSlideRenderControlProps) => {
  const touchArea = cx('bg-transparent b bn white', styles.touchArea);
  return (
    <button className={touchArea} onClick={previousSlide} title="Anterior" aria-label="Anterior">
      <Icon name="SvgIconArrowleft" customId="left-control" />
    </button>
  );
};
