import React from 'react';
import cx from 'classnames';
import Carousel from 'nuka-carousel';
import { LeftControl } from '../../../../../components/CardAd/LeftControl';
import { RightControl } from '../../../../../components/CardAd/RightControl';
import { ShareAndLike } from '../../../../../components/ShareAndLike';
import { callAll } from '../../../../../utils/callAll';
import styles from '../../Gallery.module.css';

interface Photo {
  id: string;
  photo: string;
  title?: string;
  alt?: string;
}

interface Props {
  mainPhoto: Photo;
  onClick: () => void;
  setCurrentPhoto: any;
  onlyMainPhoto: boolean;
  withoutMainPhoto?: boolean;
  onSharedClick?: any;
  model?: string;
  color?: string;
  photos?: Photo[] | null;
  dataValue?: string;
}

const CarouselPhotoWrapper: React.FC<{
  mainPhoto: Photo;
  photos?: Photo[] | null;
  onlyMainPhoto: boolean;
  withoutMainPhoto?: boolean;
}> = ({ mainPhoto, photos, onlyMainPhoto, withoutMainPhoto }) => {
  const hasAdditionalPhotos = photos && photos.length > 0;
  const photoGalleryImg = cx(
    'w-100 transition-ease h-auto',
    styles.photoGalleryImg,
    styles.objectFit,
    {
      [styles.onlyMainPhoto]: !withoutMainPhoto && (hasAdditionalPhotos || onlyMainPhoto),
      [styles.customCarousel]: hasAdditionalPhotos,
    },
  );
  const mainPhotoComponent = (
    <img
      src={mainPhoto.photo}
      alt={mainPhoto.alt}
      title={mainPhoto.title}
      className={photoGalleryImg}
    />
  );
  if (!hasAdditionalPhotos) {
    return mainPhotoComponent;
  }
  const photosToRender =
    photos &&
    photos.map(({ id, photo }, index) => (
      <img
        key={`${id}+${index + 1}`}
        src={photo}
        alt={`${index + 2} - ${mainPhoto.alt}`}
        title={`${index + 2} - ${mainPhoto.title}`}
        className={photoGalleryImg}
      />
    ));
  return (
    <Carousel
      renderCenterLeftControls={LeftControl}
      renderCenterRightControls={RightControl}
      swiping
    >
      {mainPhotoComponent}
      {photosToRender}
    </Carousel>
  );
};

export const MainPhotoInGallery = ({
  mainPhoto,
  onClick,
  setCurrentPhoto,
  onlyMainPhoto,
  onSharedClick,
  model,
  color,
  dataValue,
  photos,
  withoutMainPhoto,
}: Props) => {
  const photoDefault = {
    id: '1',
    photo: `${process.env.FTP_IMAGENS}images/ad/image-veiculodefault-large.png`,
  };

  const photo = mainPhoto ? mainPhoto.photo : photoDefault.photo;

  const handleCurrentPhoto = () => setCurrentPhoto(0);

  const containerPhotoMain = cx(
    'h-100 mr8 pointer flex items-center justify-center overflow-hidden relative transition-ease w-100 flex-grow-0',
    {
      [styles.customPhotoCarouselContainer]: photos && photos.length > 0,
    },
  );

  const shareAndLikeComponent = onSharedClick ? (
    <ShareAndLike onClick={onSharedClick} dataValue={dataValue} />
  ) : null;

  const formattedMainPhoto = {
    ...mainPhoto,
    photo,
    alt: `${model} ${color}`,
    title: model,
  };

  return (
    <div className="flex h-100">
      <div className={containerPhotoMain} onClick={callAll(handleCurrentPhoto, onClick)}>
        <CarouselPhotoWrapper
          mainPhoto={formattedMainPhoto}
          onlyMainPhoto={onlyMainPhoto}
          photos={photos}
          withoutMainPhoto={withoutMainPhoto}
        />
        <div className="dn flex-m flex-l ml24-m mb16-m ml48-l pl8-l mb16-l absolute left-0 bottom-0 z-2">
          {shareAndLikeComponent}
        </div>
      </div>
    </div>
  );
};
