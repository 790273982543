import React from 'react';
import cx from 'classnames';
import { CarouselSlideRenderControlProps } from 'nuka-carousel';
import { Icon } from '../Icons';
import style from './style.module.css';

export const RightControl = ({ nextSlide }: CarouselSlideRenderControlProps) => {
  const styleControl = cx('bg-transparent b bn white h-100 pointer', style.carouselControls);

  const handleNextSlide = (event: { stopPropagation: () => void; preventDefault: () => void }) => {
    event.stopPropagation();
    event.preventDefault();
    nextSlide();
  };

  return (
    <button className={styleControl} onClick={handleNextSlide} title="Próximo" aria-label="Próximo">
      <Icon name="SvgIconArrowRight" customId="right-control-card-ad" />
    </button>
  );
};
