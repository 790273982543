import React from 'react';
import { Gallery } from '../Advertisement/Gallery/';
import { dataTutorial, firstStep, secondStep, thirdStep, fourthSteps } from './steps';
import styles from './style.module.css';

export const Tutorial = () => {
  return (
    <section className={styles.tutorial}>
      <div className={styles.container}>
        <h1>{dataTutorial.title}</h1>
        <p>{dataTutorial.content}</p>
        <div>
          <h4>{firstStep.title}</h4>
          <p>{firstStep.content}</p>
          <div className="mb32 mb72-m mb72-l">
            <Gallery gallery={firstStep.images} model={firstStep.title} color={firstStep.title} />
          </div>
        </div>
        <div>
          <h4>{secondStep.title}</h4>
          <p>{secondStep.content}</p>
          <div className="mb32 mb72-m mb72-l">
            <img src={secondStep.image} />
          </div>
        </div>
        <div>
          <h4>{thirdStep.title}</h4>
          <p>{thirdStep.content}</p>
          <div className="mb32 mb72-m mb72-l">
            <Gallery gallery={thirdStep.images} model={thirdStep.title} color={thirdStep.title} />
          </div>
        </div>
        <div>
          <h4>{fourthSteps.title}</h4>
          <p>{fourthSteps.content}</p>
          <p>{fourthSteps.secondContent}</p>
          <div className="mb32 mb72-m mb72-l">
            <img src={fourthSteps.image} />
          </div>
        </div>
      </div>
    </section>
  );
};
