export const dataTutorial = {
  title: 'Como vender',
  content:
    'Para anunciar seu veículo no site da Autoscar é simples e prático, basta seguir os seguintes passos:',
};

export const firstStep = {
  title: '1º Passo - Cadastro',
  content:
    'É necessário que o usuário seja cadastrado em nosso site para que possa anunciar seu veículo, para realizá-lo basta clicar no ícone superior do site Cadastre-se. Após clicar no ícone o usuário deverá preencher os dados para que seu cadastro seja feito.',
  images: [
    { id: '0', photo: `${process.env.FTP_IMAGENS}images/tutorial/1-link-pagina-inicial.png` },
    { id: '1', photo: `${process.env.FTP_IMAGENS}images/tutorial/2-cadastro-pj.jpg` },
  ],
};

export const secondStep = {
  title: '2º Passo - Login',
  content:
    'Após o cadastro feito o usuário deverá fazer seu login informando seu e-mail e senha feita no cadastro. Em sequência o usuário entrará na sua área restrita onde irá visualizar um botão com os dizeres: “Anuncie Aqui”',
  image: `${process.env.FTP_IMAGENS}images/tutorial/3-login.png`,
};

export const thirdStep = {
  title: '3º Passo - Fazendo o anúncio',
  content:
    'Depois de clicar no botão “Anuncie Aqui” irá abrir uma página solicitando o cadastro do tipo de veículo que se deseja vender. São oferecidos os serviços de vendas na Autoscar de automóvel, moto, caminhão, ônibus e barco. Após selecionar o tipo de veículo, uma página com os dados do veículo como marca, modelo, ano, tipo de motor, preço, aparecerão para serem preenchidos, lembrando que todos estes dados e os demais solicitados deverão ser preenchidos.',
  images: [
    { id: '0', photo: `${process.env.FTP_IMAGENS}images/tutorial/4-criar-anuncio.png` },
    { id: '1', photo: `${process.env.FTP_IMAGENS}images/tutorial/5-selecionar-tipo-anuncio.png` },
    { id: '2', photo: `${process.env.FTP_IMAGENS}images/tutorial/5-1-primeiro-passo.png` },
    { id: '3', photo: `${process.env.FTP_IMAGENS}images/tutorial/5-2-segundo-passo.png` },
    { id: '4', photo: `${process.env.FTP_IMAGENS}images/tutorial/5-3-terceiro-passo.png` },
    { id: '5', photo: `${process.env.FTP_IMAGENS}images/tutorial/5-4-quarto-passo.png` },
    { id: '6', photo: `${process.env.FTP_IMAGENS}images/tutorial/5-5-quinto-passo.png` },
  ],
};

export const fourthSteps = {
  title: '4º Passo - Selecionando o plano / Forma de pagamento',
  content:
    'No passo seguinte o usuário deverá selecionar qual dos planos oferecidos por nosso site ele vai querer solicitar como forma de anunciar seu veiculo, além de poder ter acesso as estatísticas de visitação do veículo através do painel de controle, lembrando que estes são os oferecidos à pessoa física, tendo a jurídica de clicar em Planos para revendedores. Dentre os planos oferecidos pela Autoscar estão os de:',
  secondContent:
    'Após selecionar o plano desejado, o usuário deverá clicar em “Escolher Plano”, onde a página será direcionada para a forma de pagamento desejada pelo anunciante, dentre as formas de pagamento oferecidas estão: cartões de crédito, débito online/TEF e boleto bancário. Lembrando que o pagamento é garantido e protegido pelo PagSeguro do UOL. Após a confirmação do pagamento, o anúncio é liberado e colocado no site.',
  image: `${process.env.FTP_IMAGENS}images/tutorial/6-planos.png`,
};
