import React from 'react';
import map from 'lodash/map';
import { CarouselPhotos } from '../../../../../components/CarouselPhotos';
import { Photo } from './Photo';

interface OptionsGallery {
  id: string;
  photo: string;
}

interface Props {
  photos: OptionsGallery[];
  model?: string;
  color?: string;
}

export const ListPhotosMobile = ({ photos, model, color }: Props) => {
  const photoDefault = {
    id: '1',
    photo: `${process.env.FTP_IMAGENS}images/ad/image-veiculodefault-large.png`,
  };
  const envPhoto = photos.length ? photos : [photoDefault];

  const photosList = map(envPhoto, ({ id, photo }, index) => (
    <Photo
      key={`${photo}-${id}`}
      photo={photo}
      alt={`Foto da galeria ${index + 1}: ${model} ${color}`}
      title={model || ''}
    />
  ));

  return (
    <div className="flex">
      <CarouselPhotos>{photosList}</CarouselPhotos>
    </div>
  );
};
