import React from 'react';
import cx from 'classnames';
import styles from '../../Gallery.module.css';

interface Props {
  photo: string;
  title: string;
  alt: string;
}
export const Photo = ({ photo, alt, title }: Props) => {
  const imgBox = cx('ma4-ns flex-auto cover bg-center', styles.photo, styles.objectFit);
  return (
    <div
      role="img"
      className={imgBox}
      style={{ backgroundImage: `url(${photo})` }}
      aria-label={alt}
      title={title}
    />
  );
};
