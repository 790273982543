import React from 'react';
import cx from 'classnames';
import { CarouselSlideRenderControlProps } from 'nuka-carousel';
import { Icon } from '../Icons';
import styles from './style.module.css';

export const RightControl = ({ nextSlide }: CarouselSlideRenderControlProps) => {
  const touchArea = cx('bg-transparent b bn white', styles.touchArea);

  return (
    <button className={touchArea} onClick={nextSlide} title="Próxima" aria-label="Próxima">
      <Icon name="SvgIconArrowRight" customId="right-control-carousel" />
    </button>
  );
};
