import React from 'react';
import { Layout } from '../components/Layout';
import { Footer } from '../modules/Footer';
import { Menu } from '../modules/Menu';
import { Tutorial } from '../modules/Tutorial';

const HowToSell = () => (
  <Layout title="Como vender | Autoscar">
    <Menu menuHasTwoColors={false} />
    <Tutorial />
    <Footer />
  </Layout>
);

export default HowToSell;
