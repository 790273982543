import React from 'react';
import { ContainerListPhotos } from './ListPhotos/components/ContainerListPhotos';

interface Option {
  id: string;
  photo: string;
}

interface Props {
  gallery: Option[];
  onSharedClick?: any;
  model?: string;
  color?: string;
  type?: 'vehicle' | 'product';
  dataValue?: string;
  withoutMainPhoto?: boolean;
}

export const Gallery = ({
  gallery,
  onSharedClick,
  model,
  color,
  type = 'vehicle',
  dataValue,
  withoutMainPhoto,
}: Props) => (
  <ContainerListPhotos
    gallery={gallery}
    onSharedClick={onSharedClick}
    model={model}
    color={color}
    dataValue={dataValue}
    type={type}
    withoutMainPhoto={withoutMainPhoto}
  />
);
